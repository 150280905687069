import { Field } from 'components/FinalForm';
import { FieldCheckbox } from 'components/FormFields';
import GamePlanSelectContactTable from 'components/Table/GamePlanSelectConTactTable';
import { UIButton, UIActionButton } from 'components/UI';
import { ContactCombineCompany } from 'interfaces/gameplan';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import AddSVG from 'assets/images/icons/add-icon.svg';
import { OnCheckParams } from 'components/UI/interfaces';
import { PAGE_WIDTH } from 'constants/common';
import { useModal } from 'hooks/common';
import { fetchErrorHandler } from 'utils/errorHandlers';
import ImportContactsModal from 'components/Modals/GameplanImportContactsModal/GameplanImportContactsModal';
import { UploadContactsReq } from 'interfaces/gameplan';
import { fetchSaveContactsXLSX } from 'store/ducks/gameplan/api';
import Toast from 'services/Toast';
import UploadButton from 'components/ActionButtons/UploadButton';
import ExportButton from 'components/ActionButtons/ExportButton';

interface Props {
  datasource: ContactCombineCompany[];
  openAddContactsModal: () => void;
  showImportContactsModal: () => void;
  isCheckedAll: boolean;
  checkedContactsIds: string[];
  onCheckRow: ({ check, id }: OnCheckParams) => void;
  onCheckAll: (bool: boolean) => void;
}

const SelectContacts = ({
  datasource,
  checkedContactsIds,
  openAddContactsModal,
  isCheckedAll,
  onCheckAll,
  onCheckRow,
  showImportContactsModal,
}: Props) => {
  return (
    <>
      <Total>
        <div>{datasource.length} contacts</div>
        <Actions>
          <SpacedActionButton>
            <ExportButton
              uploadHandler={showImportContactsModal}
              iconCss={{ transform: 'rotate(180deg)' }}
              name="Import Contact List"
            />
          </SpacedActionButton>
          <ActionButton
            handleClick={openAddContactsModal}
            icon={AddSVG}
            name="Add Contact"
          />
          <Field name="isBbc" label="Send a copy to me" component={FieldCheckbox} />
        </Actions>
      </Total>
      <GamePlanSelectContactTable
        rows={datasource}
        checkedAll={isCheckedAll}
        checkedContactsIds={checkedContactsIds}
        onCheckRow={onCheckRow}
        onCheckAll={onCheckAll}
      />
    </>
  );
};

const Total = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Actions = styled.div`
  display: flex;
`;

const ActionButton = styled(UIActionButton)`
  margin-right: 16px;

  svg path {
    stroke: #2baee0;
  }
`;

const SpacedActionButton = styled.div`
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
`;

export default SelectContacts;
